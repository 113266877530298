<div class="grid
            fx-layout-column fx-layout-row--gt-xs fx-layout-wrap--gt-xs
            fx-align--start-x fx-align--x-start
            fx-gap--0px fx-gap--8px--xs
            ">

  <div *ngFor="let tile of shopData; let i = index"
       class="grid-tile"
       [ngClass]="{'fx-flex--50': i != 0 || !highlightPrimary,
                   'fx-flex--66': i == 0 && highlightPrimary,
                   'fx-flex--33': i == 1 && highlightPrimary}">
    <div class="inner" [routerLink]="tile.link"
         [lazyLoad]="tile.image"
         defaultImage="/assets/images/blank.png"
         [title]="tile.title">
      <h2 [routerLink]="tile.link">
        {{ tile.title}}
      </h2>
      <div *ngIf="tile.content" [innerHtml]="tile.content | sanitizer:'html'" (click)="contentClicked($event)">

      </div>
    </div>
  </div>
</div>

<mat-spinner color="secondary" *ngIf="!shopData"></mat-spinner>
