import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mbnm-navigation-tile',
  templateUrl: './navigation-tile.component.html',
  styleUrls: ['./navigation-tile.component.scss']
})
export class NavigationTileComponent implements OnInit {

  @Input()
  tile: any;

  @Input()
  mode = '';

  @Input()
  linkStyle = 'text';

  constructor() { }

  ngOnInit() {

  }
}
