import { Injectable } from '@angular/core';
import {Observable, ReplaySubject, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {StateManagerService} from './state-manager.service';

@Injectable()
export class ProductsService {

  private baseUrl = environment.baseUrls().api;
  private cmsBaseUrl = this.baseUrl + 'cms/';

  constructor(private http: HttpClient,
              private stateManager: StateManagerService) {
    this.stateManager.setPrefix('PROD');
  }

  private static cache:any = {};
  get(type: string, activeOnly: boolean): Observable<any> {
    if (!ProductsService.cache[type]) {
      ProductsService.cache[type] = new ReplaySubject(1);
      const o = this.stateManager.getStateKeyValue('get-' + type);
      if (this.stateManager.hasValue(o.val)) { return of(o.val); }
      this.http
        .get(this.baseUrl + 'products/' + type + (activeOnly ? '?activeOnly=true' : ''))
        .subscribe( (result) => {
          this.stateManager.setStateValue(o.key, result);
          ProductsService.cache[type].next(result);
        });
    }

    return ProductsService.cache[type];
  }

  discounts(qtys: Array<number>, productTypes: Array<string>): Observable<any> {
    const key = qtys.join(',') + '/' + productTypes.join(',');
    if (!ProductsService.cache[key]) {
      const o = this.stateManager.getStateKeyValue('discounts-' + key);
      if (this.stateManager.hasValue(o.val)) { return of(o.val); }
      ProductsService.cache[key] = new ReplaySubject(1);
      this.http
        .get(this.baseUrl + 'products/discount/' + key)
        .subscribe( (result) => {
          this.stateManager.setStateValue(o.key, result);
          ProductsService.cache[key].next(result);
        });
    }

    return ProductsService.cache[key];
  }


  categories(qty:number, type:string): Observable<any> {
    const o = this.stateManager.getStateKeyValue('categories-' + qty + '-' + type);
    if (this.stateManager.hasValue(o.val)) { return of(o.val); }
    return this.http
      .get(this.baseUrl + 'products/categories/' + qty + '/' + type)
      .pipe(tap( result => this.stateManager.setStateValue(o.key, result) ));
  }

  matchItemByTags(item, tags):boolean {

    let match:boolean = true;

    if (tags) {
      for (let key in tags) {
        let val = typeof item[key] !== 'undefined' ? item[key].toString().toLowerCase() : null;
        match = match && typeof tags[key] !== 'undefined' ? tags[key].toString().toLowerCase().split(',').indexOf(val) >= 0 : false;
      }
    }

    return match;
  }

  filterByTags(data, tags): Array<any> {
    if (!tags) {
      return data;
    }

    return data.filter( item => {
      return this.matchItemByTags(item, tags);
    });
  }

  getTags(options: any) {
    // calculate the tags
    const tags = {};
    options.forEach( option => {
      if (option.selection) {
        tags[option.tagName] = option.selection;
      }
    });
    return tags;
  }

  predefinedProduct(id: string): Observable<any> {
    if (id) {
      const o = this.stateManager.getStateKeyValue('predefinedProduct-' + id);
      if (this.stateManager.hasValue(o.val)) { return of(o.val); }

      return this.http
        .get(this.cmsBaseUrl + 'product?id=' + id)
        .pipe(
            map( (result: any) => {
              const retValue = {
                pageTitle: result.pageTitle ? result.pageTitle : result.name,
                metaDescription: result.metaDescription ? result.metaDescription : (result.description ? result.description : result.name),
                projectData: result.projectData ? {
                  outerSize: result.projectData.outerSize,
                  openings: result.projectData.openings ? result.projectData.openings : null,
                  qty: result.project ? result.project.qty : 1,
                  topMat: result.projectData.topMat ? result.projectData.topMat : result.projectData.mats ? result.projectData.mats[0][0] : null,
                  bottomMat: result.projectData.bottomMat ? result.projectData.bottomMat : result.projectData.mats && result.projectData.mats[1] ? result.projectData.mats[1][0] : null,
                  moulding: result.projectData.moulding ? result.projectData.moulding : null,
                  glazing: result.projectData.glazing ? result.projectData.glazing : null,
                  backingBoard: result.projectData.backingBoard ? result.projectData.backingBoard : null,
                  bag: result.projectData.bag ? result.projectData.bag : null,
                  design: result.projectData.design ? result.projectData.design : (result.projectData.designId ? {id: result.projectData.designId} : null),
                  permanentDesignId: result.projectData.permanentDesignId,
                  complexityUpCharge: result.projectData.complexityUpCharge,
                  reveal: result.projectData.reveal,
                  primaryProduct: result.projectData.primaryProduct,
                  restrictions: result.projectData.restrictions,
                  print: result.projectData.print
                } : null
              };
              this.stateManager.setStateValue(o.key, retValue);
              return retValue;
            })
        );
    } else {
      return of(null);
    }
  }
}
