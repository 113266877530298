import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Project} from '../models/project';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, mergeMap} from 'rxjs/operators';
import {Opening} from '../models/opening';
import {environment} from '../../../environments/environment';

@Injectable()
export class ProjectService {

  public baseUrl = environment.baseUrls().api;
  constructor(private httpClient: HttpClient) {
    this.standardSizes.forEach((size) => {
      // fill in needed info for each size
      const reveal = size.reveal ? size.reveal : 0.25;
      size.opening = size.opening ? size.opening : [size.artwork[0] - reveal * 2, size.artwork[1] - reveal * 2];
    });
  }

  getOrCreate(param:string, data:any = null): Observable<Project> {
    if ('MAT,FRAME,BACKINGBOARD,GLAZING,SHOWBAG,SAMPLE'.split(',').indexOf(param) > -1) {
      return this.create(param, data);
    } else {
      return this.get(param, true);
    }
  }

  create(primaryProduct:string, data:any = null): Observable<Project> {
    const project = new Project(data ? data : {'offset': 0, 'openings': primaryProduct === 'MAT' ? [Opening.INDETERMINATE] : [], 'primaryProduct': primaryProduct}, this);
    return this.getPrice(project)
      .pipe(
        mergeMap((res: any) => {
            project.updatePrice(res);
            const retValue = new Subject<Project>();
            setTimeout( () => {
              retValue.next(project);
            })
            return retValue;
        })
      )
  }

  get(id: string, fromCart: boolean): Observable<Project> {
    return this.httpClient
      .get(this.baseUrl + (fromCart ? 'cart/' : '') + 'project/' + id)
      .pipe(
        map( (res:any) => {
          if (res) {
            res.originalId = fromCart && id !== 'temp' ? res.id : null;
            res.originalIdForFirstPreview = res.id;
            res.id = null;
            return new Project(res, this);
          } else {
            return null;
          }
        })
      );
  }

  update(project: Project): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.httpClient
      .put(this.baseUrl + 'cart/project/', project.toJSON(), { headers: headers})
      .pipe(
        map((res: any) => {
          project.version = res.version;
          project.id = res.id;
          project.updatePrice(res);
          return true;
        })
      );
  }

  /*

  Not in use
  getPriceAtQtys(qtys: Array<number>): Observable<any> {
    return this.httpClient
      .get(this.baseUrl + 'cart/project/price/' + qtys.join(','));
  }
  */

  getPrice(project: Project, quick: boolean = false): Observable<Project> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient
      .post(this.baseUrl + 'cart/project/price', project.toJSON(quick), { headers: headers})
      .pipe(
        map( (res:any) => {
          project.updatePrice(res);
          return project;
        })
      );
  }

  standardSizes: Array<any> =  [
    { visible: true, artwork: [4,6], outer: [5,7], opening: [3.5,5.5] },
    { visible: false, artwork: [3.5,5], outer: [5,7], opening: [3,4.5] },
    { visible: false, artwork: [4,4], outer: [6,6], opening: [3.5,3.5] },
    { visible: false, artwork: [4,4], outer: [8,8], opening: [3.5,3.5] },
    { visible: true, artwork: [5,5], outer: [8,8], opening: [4.5,4.5] },
    { visible: true, artwork: [5,7], outer: [8,10], supportsClearBags: true },
    { visible: true, artwork: [4,6], outer: [8,10], supportsClearBags: true },
    { visible: true, artwork: [6,9], outer: [9,12], supportsClearBags: true },
    { visible: true, artwork: [7,7], outer: [10,10], opening: [6.5,6.5] },
    { visible: false, artwork: [6,6], outer: [10,10], opening: [5.5,5.5] },
    { visible: false, artwork: [5,5], outer: [10,10], opening: [4.5,4.5] },
    { visible: true, artwork: [8,10], outer: [11,14], supportsClearBags: true },
    { visible: true, artwork: [7,10], outer: [11,14], supportsClearBags: true },
    { visible: true, artwork: [8,12], outer: [11,14], supportsClearBags: true },
    { visible: true, artwork: [8.5,11], outer: [11,14], supportsClearBags: true },
    { visible: false, artwork: [8,12], outer: [12,16], supportsClearBags: true},
    { visible: true, artwork: [9,12], outer: [12,16] },
    { visible: false, artwork: [8,10], outer: [12,16] },
    { visible: true, artwork: [11,14], outer: [14,18] },
    { visible: false, artwork: [9,12], outer: [14,18], supportsClearBags: true},
    { visible: false, artwork: [8,12], outer: [14,18] },
    { visible: true, artwork: [11,14], outer: [16,20], supportsClearBags: true },
    { visible: true, artwork: [12,16], outer: [16,20], supportsClearBags: true },
    { visible: false, artwork: [8.5,11], outer: [16,20], supportsClearBags: true },
    { visible: false, artwork: [10,15], outer: [16,20], supportsClearBags: true },
    { visible: true, artwork: [12,18], outer: [18,24], supportsClearBags: true },
    { visible: false, artwork: [11,17], outer: [18,24], supportsClearBags: true },
    { visible: true, artwork: [13,19], outer: [18,24], supportsClearBags: true },
    { visible: true, artwork: [16,20], outer: [20,24], supportsClearBags: true },
    { visible: true, artwork: [16,20], outer: [20,30], supportsClearBags: true },
    { visible: true, artwork: [18,24], outer: [22,28], supportsClearBags: true },
    { visible: true, artwork: [20,24], outer: [24,30] },
    { visible: false, artwork: [18,24], outer: [24,30] },
    { visible: true, artwork: [20,30], outer: [24,36] },
    { visible: false, artwork: [24,32], outer: [30,40] },
    { visible: true, artwork: [24,34], outer: [30,40] },
    { visible: true, artwork: [24,32], outer: [32,40] },
    { visible: true, artwork: [0,0], opening: [0,0], outer: [32,40] },
    { visible: false, artwork: [24,34], outer: [32,40] }
  ];
}
