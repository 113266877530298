import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SeoService} from "../../services/seo.service";
import {CmsService} from "../../services/cms.service";


@Component({
  selector: 'mbnm-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

  constructor(    private route: ActivatedRoute,
                  private router: Router,
                  private cms: CmsService,
                  public seoService: SeoService) {}

  shopData: any;

  @Input() highlightPrimary: boolean = true;
  @Input() slug = null;
  @Input() maxTiles: number = null;
  ngOnInit() {
    this.route.url
      .subscribe(url => {
        const slug = this.slug ? this.slug : (url.length === 0 ? '' : url[0].path);
        this.cms
          .pageChildren(slug)
          .then((page) => {
            if (slug !== 'index' && !this.slug) {
              this.seoService.setMetaData({
                title: page.pageTitle,
                description: page.metaDescription,
                og: {
                  image: page.children && page.children.length > 0 ? page.children.image : null
                }
              });
            }
            this.shopData = page.children;
            if (this.maxTiles) {
              this.shopData = this.shopData.slice(0, this.maxTiles);
            }
          }, (err) => {
            this.router.navigateByUrl('/not-found',{ skipLocationChange: true });
          });
      });
  }

  private findLink(el: any): string {
    if (!el) return null;
    if (el.tagName === 'A') return el.getAttribute('href');
    return this.findLink(el.parentNode);
  }

  public contentClicked($event: any) {
    const link = this.findLink($event.target);
    if (link) {
      $event.preventDefault();
      this.router.navigateByUrl(link);
    }
  }
}
